import React from "react";
import IntroBanner from "../../../components/IntroBannerRevamp";
import Seo from "../../../components/Seo";
import Layout from "../../../layouts";
import Banner from "../../../images/services/salesforce/Salesforce-Cover2.png";
import ContrastSection from "../../../components/ContrastSection";
// import GetInTouchbtn from "../../components/GetInTouchbtn";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
} from "reactstrap";

// import iconBalance from "../../images/services/salesforce/icon/balance.svg";
// import iconCost from "../../images/services/salesforce/icon/costeffective.svg";
// import iconProfessional from "../../images/services/salesforce/icon/professional.svg";
// import iconRisk from "../../images/services/salesforce/icon/risksystem.svg";

// import iconExperience from "../../images/services/salesforce/icon/experience.svg";
// import iconCompetency from "../../images/services/salesforce/icon/competencies.svg";
// import iconTesting from "../../images/services/salesforce/icon/testing.svg";

//import iconCertified from "../../images/services/salesforce/icon/certified.svg";
// import iconGdpr from "../../images/services/qa/icon/gdpr.svg";
//import iconTools from "../../images/services/salesforce/icon/build.svg";

//import Chanakya from "../../images/services/salesforce/chanakya.png";
import Chamil from "../../../images/services/salesforce/chamil4.png";
import Sylvia from "../../../images/services/salesforce/sylvia.jpeg";

//import iconAnalysis from "../../images/process-analysis.svg";
// import Whodepemdonus from "../../components/WhoDependsOnUs";
import UserCard from "../../../components/UserCard";
import iconStrategic from '../../../images/services/salesforce/icon/strategic-guidance.svg';
import iconExpertise from '../../../images/services/salesforce/icon/expertise.svg';
import iconInnovation from '../../../images/services/salesforce/icon/innovation.svg';
import iconFlexibility from '../../../images/services/salesforce/icon/flexibility.svg';
import iconRecord from '../../../images/services/salesforce/icon/record.svg';
import iconSatisfaction from '../../../images/services/salesforce/icon/satisfaction.svg';
import iconGlobal from '../../../images/services/salesforce/icon/global.svg';
import iconTrend from '../../../images/services/salesforce/icon/trend.svg';
import iconExpert from '../../../images/services/salesforce/icon/expert.svg';
import Service1 from '../../../images/services/salesforce/SalesforceService1.jpg';
import Service2 from '../../../images/services/salesforce/SalesforceService2.jpg';
import Service3 from '../../../images/services/salesforce/SalesforceService3.jpg';
import Service4 from '../../../images/services/salesforce/SalesforceService4.jpg';
import Service5 from '../../../images/services/salesforce/SalesforceService5.jpg';
import Service6 from '../../../images/services/salesforce/Salesforce2Service6.png';
import GigaClear from '../../../images/services/logo/gigaclear.png';
import Cloud from '../../../images/services/salesforce/cloud.png';
import Cloud2 from '../../../images/services/salesforce/cloud2.png';
import partner from '../../../images/partners/salesforce-partner.png';
import Cloud1 from '../../../images/services/salesforce/sales-cloud-1.png';
// import Cloud2 from '../../../images/services/salesforce/service-cloud-2.png';
import Cloud3 from '../../../images/services/salesforce/marketing-cloud-3.png';
import Cloud4 from '../../../images/services/salesforce/experience-cloud-4.png';
import Cloud5 from '../../../images/services/salesforce/education-cloud-5.png';
import Cloud6 from '../../../images/services/salesforce/public-cloud-6.png';
import Cloud7 from '../../../images/services/salesforce/financial-cloud-7.png';
import Cloud8 from '../../../images/services/salesforce/health-cloud-8.png';
import Platform from '../../../images/services/salesforce/platform.png';
import HelpSection from "../../../components/Help";

const salesforce = () => {
  return (
    <Layout bodyClass="service-page">
      <Seo
        keyword="Salesforce Development Services"
        title="Top Salesforce Development Services | Enhance Your CRM Solutions"
        description="Unlock the full potential of your business with our top-notch Salesforce Development Services. From custom Salesforce solutions to seamless integration, we enhance your CRM strategy to drive growth and efficiency. Discover how we can transform your business today."
      />

      <IntroBanner
        title="Salesforce Development Services"
        subtitle="Salesforce development, integration and app exchange services"
        image={Banner}
      />

      <div className="content">
        <ContrastSection
          title="Elevating Your Salesforce Journey"
          para="Salesforce stands out as a leading intelligent CRM platform for businesses of all sizes driving customer relationship management, business automation, and data-driven insights. Whether you're initiating a new salesforce implementation, optimising existing processes, or seeking to harness the full potential of the platform, partnering with a seasoned salesforce service provider is essential. At iTelaSoft, we offer a holistic suite of salesforce services designed to empower your organisation across every stage of your salesforce journey."
        />
      </div>
      <section>
        <Container className="mt-5">
          <Row className="mt-4 Y-Salesforce">
            <Col sm={5} md={6} lg={4}>
              <div>
                <img
                  alt="salesforce logo"
                  className="img-fluid show"
                  src={partner}
                />
              </div>
            </Col>
          </Row>
          <div className="title title-1 mt-4 mb-2">Why Choose a Salesforce Partner?</div>
          <div className="sub-title">
            Collaborating with a trusted salesforce partner like us presents numerous benefits:{" "}
          </div>

          <Row className="mt-4 Y-Salesforce">
            <Col md={6} lg={6}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconStrategic}
                  />
                  <CardTitle tag="h5">
                    Strategic Guidance{" "}
                  </CardTitle>
                  <CardText>
                    Gain strategic insights and personalised recommendations tailored to your business objectives, industry dynamics, and growth aspirations.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={6}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconExpertise}
                  />
                  <CardTitle tag="h5">
                    End-to-End Expertise
                  </CardTitle>
                  <CardText>
                    Access a diverse team of salesforce specialists equipped with deep domain knowledge, technical proficiency, and hands-on experience across various salesforce clouds, including Sales Cloud, Service Cloud, Marketing Cloud, Commerce Cloud, and more.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={6}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconInnovation}
                  />
                  <CardTitle tag="h5">
                    Innovation and Best Practices
                  </CardTitle>
                  <CardText>
                    Stay ahead of the curve with access to innovative salesforce technologies, best practices, and industry benchmarks, enabling you to unlock new opportunities, drive innovation, and optimise business outcomes.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={6}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconFlexibility}
                  />
                  <CardTitle tag="h5">
                    Flexibility and Scalability
                  </CardTitle>
                  <CardText>
                    Benefit from flexible engagement models, scalable resources, and on-demand services that align with your evolving needs, budget constraints, and project timelines.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className="service-container">
          <Row>
            <Col>
              <div>
                <div className="title title-1 mb-2">Our Comprehensive Salesforce Service Offerings</div>
                <div className="sub-title">
                  At iTelaSoft, we offer a comprehensive portfolio of salesforce services to address the diverse needs and challenges faced by organisations across industries:{" "}
                </div>
              </div>
              <div className="mt-4">

                <div className="container section-2">
                  <div className="row">
                    <div className="col-md-6 pr-md-5">
                      <img
                        alt="Salesforce Consulting and Advisory"
                        className="img-fluid"
                        src={Service1}
                      />
                    </div>
                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">
                        Salesforce Consulting and Advisory
                      </h2>
                      <p>
                        Kickstart your salesforce journey with strategic consulting services encompassing business process analysis, roadmap development, solution architecture, and change management strategies.
                      </p>
                    </div>
                  </div>
                  <div className="row py-6">
                    <div className="col-md-6 pr-md-5 order-md-2">
                      <img
                        alt="product innovation"
                        className="img-fluid"
                        src={Service2}
                      />
                    </div>
                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">
                        Salesforce Implementation and Customisation
                      </h2>
                      <p>
                        Leverage our expertise to streamline salesforce implementations, customisations, and migrations, ensuring seamless adoption, user acceptance, and alignment with your unique business requirements.
                      </p>
                    </div>
                  </div>
                  <div className="row py-6">
                    <div className="col-md-6 pr-md-5">
                      <img
                        alt="Salesforce Integration and Data Management"
                        className="img-fluid"
                        src={Service3}
                      />
                    </div>
                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">Salesforce Integration and Data Management</h2>
                      <p>
                        Harness the power of data by integrating salesforce with your existing systems, applications, and third-party platforms. Our integration services encompass data migration, API integration, middleware configuration, and master data management to ensure data integrity and interoperability.
                      </p>
                    </div>
                  </div>
                  <div className="row py-6">
                    <div className="col-md-6 pr-md-5 order-md-2">
                      <img
                        alt="Salesforce App Development and Customisation"
                        className="img-fluid"
                        src={Service4}
                      />
                    </div>
                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">
                        Salesforce App Development and Customisation
                      </h2>
                      <p>
                        Extend the functionality of salesforce to meet your specific business needs with custom app development, Lightning component development, and salesforce AppExchange solutions tailored to enhance productivity, collaboration, and customer engagement.
                      </p>
                    </div>
                  </div>
                  <div className="row py-6">
                    <div className="col-md-6 pr-md-5">
                      <img
                        alt="Salesforce Training and Enablement"
                        className="img-fluid"
                        src={Service5}
                      />
                    </div>
                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">Salesforce Training and Enablement</h2>
                      <p>
                        Empower your teams with comprehensive salesforce training programs, workshops, and certification courses designed to enhance user proficiency, drive adoption, and maximise ROI from your salesforce investments.
                      </p>
                    </div>
                  </div>
                  <div className="row py-6">
                    <div className="col-md-6 pr-md-5 order-md-2">
                      <img
                        alt="Salesforce Support and Managed Services"
                        className="img-fluid"
                        src={Service6}
                      />
                    </div>
                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">
                        Salesforce Support and Managed Services
                      </h2>
                      <p>
                        Ensure the ongoing success of your salesforce initiatives with our dedicated support services, including proactive monitoring, troubleshooting, performance optimisation, and continuous improvement initiatives to maximise uptime, reliability, and user satisfaction.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className="Y-ptnr-Itelasoft">
          <div>
            <div className="title title-1 mb-2">Why Partner with iTelaSoft?</div>
            <div className="sub-title">
              iTelaSoft is your trusted partner for salesforce success, offering unparalleled expertise, innovation, and value across every facet of your salesforce journey:
            </div>
          </div>
          <Row className="mt-4">
            <Col md={6} lg={4}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconRecord}
                  />
                  <CardTitle tag="h5">
                    Proven Track Record
                  </CardTitle>
                  <CardText>
                  With a proven track record of successful salesforce implementations, integrations, and optimisations, we bring a wealth of experience and insights to every engagement.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={4}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconSatisfaction}
                  />
                  <CardTitle tag="h5">
                    Customer-Centric Approach
                  </CardTitle>
                  <CardText>
                    We prioritise customer satisfaction, collaboration, and transparency, fostering long-term partnerships built on trust, integrity, and shared success.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={4}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconGlobal}
                  />
                  <CardTitle tag="h5">
                    Global Reach, Local Presence
                  </CardTitle>
                  <CardText>
                    With a global footprint and local expertise, we serve clients across diverse industries, geographies, and organisational sizes, ensuring personalised service and support tailored to your unique needs.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={4}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconTrend}
                  />
                  <CardTitle tag="h5">
                    Continuous Innovation
                  </CardTitle>
                  <CardText>
                    We stay ahead of the latest salesforce innovations, trends, and best practices, enabling us to deliver innovative solutions that drive business transformation, agility, and competitive advantage. 
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={4}>
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconExpert}
                  />
                  <CardTitle tag="h5">
                    Certified Salesforce Experts
                  </CardTitle>
                  <CardText>
                    Our team comprises certified salesforce consultants, developers, administrators, and architects who possess the skills, certifications, and credentials to deliver exceptional results and exceed your expectations.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-our-partners">
        <Container>
          <Row>
            <Col md={12} lg={12}>
              {/* <Whodepemdonus /> */}
              <div id="who-depend-on-us">
                {" "}
                <div className="title-1 mb-2">Who Depends on Us? </div>
                <div className="section-discription">
                  Number of organisations ranging from start-ups, scale-ups, to large
                  enterprises depend on iTelaSoft for Quality Engineering and Validation
                  services.
                </div>
                <div className="section-our-partners-logo-set">
                  <a href="https://gigaclear.com/" target="_blank">
                    <div className="wrap-logo p-0">
                      <div className="partners-logo">
                        {" "}
                        <img src={GigaClear} alt=""></img>
                      </div>
                      {/* <div className="logo-dec">Gigaclear</div> */}
                    </div>
                  </a>
                </div>
              </div>
            </Col>
            {/* <Col md={12} lg={12} className="sf-partners">
            </Col> */}
          </Row>
        </Container>
      </section>
      <section className="section-our-partners">
        <Container className="industry-section logo-section">
          <Row className="mt-4 industry-partners">
            <Col>
              <div className="container pb-6 section-2">
                <div className="row">
                  <div className="col-md-3 col-sm-6 col-6 cloud-logo">
                    <img
                      alt="salesforce logo"
                      className="img-fluid"
                      src={Cloud}
                    />
                    <p>Sales Cloud</p>
                  </div>
                  <div className="col-md-3 col-sm-6 col-6 cloud-logo">
                    <img
                      alt="salesforce logo"
                      className="img-fluid"
                      src={Cloud}
                    />
                    <p>Service Cloud</p>
                  </div>
                  <div className="col-md-3 col-sm-6 col-6 cloud-logo">
                    <img
                      alt="salesforce logo"
                      className="img-fluid"
                      src={Cloud}
                    />
                    <p>Field Service Lightning (FSL)</p>
                  </div>
                  <div className="col-md-3 col-sm-6 col-6 cloud-logo">
                    <img
                      alt="salesforce logo"
                      className="img-fluid"
                      src={Cloud}
                    />
                    <p>Experience Cloud</p>
                  </div>
                </div>
                <div className="row justify-content-center my-1">
                    <div className="col-md-3 col-sm-6 col-6 cloud-logo">
                      <img
                        alt="salesforce logo"
                        className="img-fluid"
                        src={Platform}
                      />
                      <p>Salesforce Platform</p>
                    </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md col-sm-6 col-6 cloud-logo">
                    <img
                      alt="salesforce logo"
                      className="img-fluid show"
                      src={Cloud2}
                    />
                    <img
                      alt="salesforce logo"
                      className="img-fluid hide"
                      src={Cloud}
                    />
                    <p>Marketing Cloud</p>
                  </div>
                  <div className="col-md col-sm-6 col-6 cloud-logo">
                    <img
                      alt="salesforce logo"
                      className="img-fluid show"
                      src={Cloud2}
                    />
                    <img
                      alt="salesforce logo"
                      className="img-fluid hide"
                      src={Cloud}
                    />
                    <p>Commerce Cloud</p>
                  </div>
                  <div className="col-md col-sm-6 col-6 cloud-logo">
                    <img
                      alt="salesforce logo"
                      className="img-fluid show"
                      src={Cloud2}
                    />
                    <img
                      alt="salesforce logo"
                      className="img-fluid hide"
                      src={Cloud}
                    />
                    <p>Health Cloud</p>
                  </div>
                  <div className="col-md col-sm-6 col-6 cloud-logo">
                    <img
                      alt="salesforce logo"
                      className="img-fluid show"
                      src={Cloud2}
                    />
                    <img
                      alt="salesforce logo"
                      className="img-fluid hide"
                      src={Cloud}
                    />
                    <p>Communication Cloud</p>
                  </div>
                  <div className="col-md col-sm-6 col-6 cloud-logo">
                    <img
                      alt="salesforce logo"
                      className="img-fluid show"
                      src={Cloud2}
                    />
                    <img
                      alt="salesforce logo"
                      className="img-fluid hide"
                      src={Cloud}
                    />
                    <p>Non Profit Cloud</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <div className="content">
          <HelpSection
            title="Let Us Know How to Help"
            para="We love to hear what challenges you go through in your business and discuss how we can assist you."
          />
        </div>
      </section>
      <section className="section-contact">
        <Container>
          <Row>
                <Col md={12} lg={6} xl={6} className="title-section">
                  <div className="title title-1 mb-2 desk">
                    Contact to <br/>
                    Discover More 
                  </div>
                  <div className="title title-1 mb-2 mobile">
                    Contact to Discover More 
                  </div>
                </Col>
                <Col md={6} lg={3} xl={3} className="col-6 mb-2">
                    <a className="contact-card" href="/contact/">
                      <UserCard
                        name="Chamil Madusanka"
                        detail="Salesforce Advisor"
                        image={Chamil}
                        link="https://www.linkedin.com/in/chamilmadusanka/"
                      />
                    </a>
                </Col>
                <Col md={6} lg={3} xl={3} className="col-6 mb-2">
                    <a className="contact-card" href="/contact/">
                      <UserCard
                        name="Sylvia Luo"
                        detail="Relationship Manager"
                        image={Sylvia}
                        link="https://www.linkedin.com/in/sylvia-luo-lol980609/"
                      />
                    </a>
                </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default salesforce;
