import React from "react";
import comment from '../images/comment.png';
import { Container } from "reactstrap";
import SvgIconsSet from "../components/SvgIconsSet";

const GetInTouch = (props) => {
  return (
    <Container fluid className="p-0">
      <div className="section-padding wrap-contrast-section help-section">
        <Container>
          <div className="contrast-section">
            <div className="contrast-section-title">
              {props.title}
             <p className="contrast-section-txt">{props.para}</p>            
            </div>

            <div className="getting-touch-right">
                <a className="btn btn-primary btn-icon-button" href="/contact/">
                    <span className="btn-text">Get in touch</span>
                    <span className="btn-icon">
                    <SvgIconsSet.SideArrow />
                    </span>
                </a>
            </div>
          </div>
        </Container>
      </div>
    </Container>
  );
};

export default GetInTouch;
